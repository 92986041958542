import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		type: 0,
		loading: false
  },
  getters: {
  },
  mutations: {
	  setType(state, type) {
		  state.type = type
	  },
	  setLoading(state, flag) {
		  state.loading = flag
	  }
  },
  actions: {
  },
  modules: {
  }
})
