import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import PanoControls from './M_panoControl'

const STATE_NONE = 0, STATE_ORBIT = 1, STATE_DEVICE = 2;

class MControls {

    constructor(object, domElement) {
        this.camera = object;
        this.el = domElement;
        this.state = STATE_NONE;
        this.states = {orbit: STATE_ORBIT, device: STATE_DEVICE};
        this.orbitControl = null;
        this.orbitControlTarget = new THREE.Vector3();
        this.panoControl = null;
        this.start();
    }

    start(state) {
        if (!state) {
            state = STATE_ORBIT
        }
        if(this.state === state)return;
        if(this.state !== STATE_NONE)this.stop();
        this.state = state;
        switch (this.state) {
            case STATE_ORBIT:
                if (this.orbitControl) {
                    this.orbitControl.enabled = true;
                }else{
                    this._initOrbitControls();
                }
                break;
            case STATE_DEVICE:
                if (this.panoControl) {
                    this.panoControl.enabled = true;
                }else{
                    this._initPanoControls();
                }
                this.panoControl.targetInit();
                break;
        }
        // this.state = state;
    }

    stop(){
        switch(this.state){
            case STATE_ORBIT:
                if(this.orbitControl)this.orbitControl.enabled = false;
                break;
            case STATE_DEVICE:
                if(this.panoControl)this.panoControl.enabled = false;
                break;
        }
        this.state = STATE_NONE;
    }

    move(target,time) {
        let cb = this.state === STATE_DEVICE ? () => {this.start(STATE_DEVICE)} : null;
        return new Promise((resolve,reject) => {
            this.stop();
            let tween = new TWEEN.Tween(this.camera.position).to(target, time).start().onComplete(() => {
                cb&&cb();
                resolve();
            });
        });

    }

    //更新控制器
    update() {
            switch(this.state){
                case STATE_ORBIT:
                    if(this.orbitControl){
                        this.orbitControl.update();
                    }
                    break;
                case STATE_DEVICE:
                    this.panoControl.update();
                    break;
            }
    }

    // 启用/停止陀螺仪
    setDeviceState(deviceEnabled = true) {
        if (!this.panoControl.devicePermission && deviceEnabled) {
            this.panoControl.deviceEventInit()
        }
        this.panoControl.deviceEnabled = deviceEnabled;
    }

    _initOrbitControls() {
        if (this.orbitControl) return this.orbitControl;
        this.orbitControl = new OrbitControls(this.camera, this.el);
        this.orbitControl.target =  this.orbitControlTarget;
        this.orbitControl.maxPolarAngle =  .38  * Math.PI;
        this.orbitControl.minPolarAngle = .38 * Math.PI;
		this.orbitControl.autoRotate = true
        this.orbitControl.autoRotateSpeed = 1.3;
        this.orbitControl.rotateSpeed = 1;
        // this.orbitControl.enableZoom = !this.sceneType;
        // this.orbitControl.VRRotate = this.isVR;
    }

    _initPanoControls() {
        if(this.panoControl)return this.panoControl;
        this.panoControl = new PanoControls(this.camera, this.el)
    }

    dispose() {
        if (this.orbitControl) this.orbitControl.dispose();
        if (this.panoControl) this.panoControl.dispose();
    }
}

export default MControls;