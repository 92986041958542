import render from '../render'
import M_Controls from './M_OrbitControls'
import event from './event'


class Control {

    constructor () {
		updateFn: null
    }

    init (el) {
       this.el = el;
	   console.log('control init')
       this.mControl = new M_Controls(render.camera, el);
	   console.log('control add updateFn', this.updateFn)
	   if (!this.updateFn){
		   this.updateFn = this.mControl.update.bind(this.mControl)
		   render.addUpdateFn(this.updateFn);
	   }
       this.event = new event(el, render.camera, this.mControl);
    }

    dispose() {
        this.mControl.dispose();
        this.event.dispose();
        render.removeUpdateFn(this.updateFn)
		this.updateFn = null
		console.log('control dispose')
    }

}

export default new Control()