import * as THREE from 'three'

let mouse = new THREE.Vector2();
let raycaster = new THREE.Raycaster();

export default {

    raycaster: raycaster,

    getRayIntersectObject(origin, direction, model) {
        raycaster.set(origin, direction);
        let intersects = raycaster.intersectObjects(model.children, true);
        if (intersects.length) {
            return intersects[0].point
        } else {
            return null
        }
    },

    //找出与射线距离最近的一个标记
    getRayDistanceToVector(target, targetList) {
        let arr = targetList.map((targetMesh) => {
            return {
                distance: targetMesh.position.distanceTo(target),
                target: targetMesh
            }
        }).sort((a, b) => {
            return a.distance - b.distance
        });
        return arr[0]
    },

    /**
     *  鼠标点击 修改光线投射器
     *  @param center 相对画布的鼠标点击坐标
     *  @param camera 相机
     *  @param el 画布节点
     */
    setRayByMouse(center, camera, el) {
        mouse.x = ( center.x / el.offsetWidth) * 2 - 1;
        mouse.y = -( center.y / el.offsetHeight) * 2 + 1;
        raycaster.setFromCamera(mouse, camera);
        return raycaster
    },

    // 向屏幕正中心发射线
    setRayBySceneCenter(camera) {
        raycaster.setFromCamera({x: 0, y: 0}, camera);
        return raycaster
    },

    // 计算当前场景的屏幕中心点
    getSceneScreenCenter(scene, camera) {
        this.setRayBySceneCenter(camera);
        return raycaster.intersectObjects(scene.children, true);
    },


    //获取轴线上距离原点的一个点
    getRayAtDis(origin, direction, dis, vector) {
        raycaster.set(origin, direction);
        return raycaster.ray.at(dis, vector)
    },
	
	// 原生事件 获取点击与3d对象的交点
	getIntersetsByEvent(event,camera,object3D,el){
		let intersets,
		center = this.getMouseCenter(event,el);
		this.setRayByMouse(center,camera,el)
		intersets = this.raycaster.intersectObject(object3D,true)
		return intersets
	},
	
	//鼠标的二维坐标，在归一化的设备坐标(NDC)中，也就是X 和 Y 分量应该介于 -1 和 1 之间
	//只适用原生事件 mouse 和 touch 系列的
	getMouseCenter(event,el){
		let center = {
			x:0,
			y:0
		};
		if(event.touches){
			center.x = event.touches[0].pageX;
			center.y = event.touches[0].pageY;
		}else{
			center.x = event.pageX;
			center.y = event.pageY
		}
		this.cailbrationCenter(center,el)
		return center
	},
	
    //校正鼠标返回的center点
    cailbrationCenter(center, el) {
        center.x -= this.offset(el, 'left');
        center.y -= this.offset(el, 'top');
        return center
    },

    offset(obj, direction) {
        //将top,left首字母大写,并拼接成offsetTop,offsetLeft
        let offsetDir = 'offset' + direction[0].toUpperCase() + direction.substring(1);

        let realNum = obj[offsetDir];
        let positionParent = obj.offsetParent;  //获取上一级定位元素对象

        while (positionParent != null) {
            realNum += positionParent[offsetDir];
            positionParent = positionParent.offsetParent;
        }
        return realNum;
    },

    /* 创建字体精灵 */
    makeTextSprite(message, parameters) {

        if (parameters === undefined) parameters = {};

        let fontface = parameters.hasOwnProperty("fontface") ?
            parameters["fontface"] : "宋体";

        /* 字体大小 */
        let fontsize = parameters.hasOwnProperty("fontsize") ?
            parameters["fontsize"] : 30;

        /* 边框厚度 */
        let borderThickness = parameters.hasOwnProperty("borderThickness") ?
            parameters["borderThickness"] : 4;

        /* 边框颜色 */
        let borderColor = parameters.hasOwnProperty("borderColor") ?
            parameters["borderColor"] : {r: 0, g: 0, b: 0, a: 1.0};

        /* 背景颜色 */
        let backgroundColor = parameters.hasOwnProperty("backgroundColor") ?
            parameters["backgroundColor"] : {r: 255, g: 255, b: 255, a: 1.0};

        /* 创建画布 */
        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');


        /* 字体加粗 */
        context.font = '20px Arial';

        let clearMaxWidth = 0;


        let  writeText = (message) => {
            /* 获取文字的大小数据，高度取决于文字的大小 */

            let metrics = context.measureText(message);
            let textWidth = metrics.width;
            context.canvas.width = textWidth;
            context.canvas.height = 200;
            // if (clearMaxWidth) {
            //     context.clearRect(0, 0, clearMaxWidth, fontsize * 1.4 + borderThickness * 2 + 5);
            // }
            // if (textWidth + borderThickness * 2 > clearMaxWidth) {
            //     clearMaxWidth = textWidth + borderThickness * 2 + 5;
            // }

            //
            // /* 背景颜色 */
            // context.fillStyle = "rgba(" + backgroundColor.r + "," + backgroundColor.g + "," + backgroundColor.b + "," + backgroundColor.a + ")";

            // /* 边框的颜色 */
            // context.strokeStyle = "rgba(" + borderColor.r + "," + borderColor.g + "," + borderColor.b + "," + borderColor.a + ")";
            // context.lineWidth = borderThickness;

            // /* 绘制圆角矩形 */
            // this.roundRect(context, borderThickness / 2, borderThickness / 2, textWidth + borderThickness, fontsize * 1.4 + borderThickness, 6);

            /* 字体颜色 */
            context.fillStyle = "rgba(255, 255, 255, 1.0)";
            context.font = "200px Arial";
            context.fillText(message, 0, 200);
        }


        if (message || message === 0) {
            writeText(message)
        }

        /* 画布内容用于纹理贴图 */
        let texture = new THREE.Texture(canvas);
        texture.needsUpdate = true;

        let spriteMaterial = new THREE.SpriteMaterial({map: texture,transparent: true,depthTest:false});
        let sprite = new THREE.Sprite(spriteMaterial);


        /* 缩放比例 */
        sprite.scale.set(400, 200, 1);

        function setPosition(v) {
            // sprite.center.set(v.x,v.y,v.z)
            sprite.position.set(v.x, v.y, v.z)
        }


        return {
            sprite: sprite,
            write: writeText,
            setPosition: setPosition
        };
    },

    /* 绘制圆角矩形 */
    roundRect(ctx, x, y, w, h, r) {

        ctx.beginPath();
        ctx.moveTo(x + r, y);
        ctx.lineTo(x + w - r, y);
        ctx.quadraticCurveTo(x + w, y, x + w, y + r);
        ctx.lineTo(x + w, y + h - r);
        ctx.quadraticCurveTo(x + w, y + h, x + w - r, y + h);
        ctx.lineTo(x + r, y + h);
        ctx.quadraticCurveTo(x, y + h, x, y + h - r);
        ctx.lineTo(x, y + r);
        ctx.quadraticCurveTo(x, y, x + r, y);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();

    },

    /**
     * 获取盒子的顶点位置
     * **/
    getBox3Vectors(box,p) {
        let size = new THREE.Vector3();
        box.getSize(size);
        return [
            new THREE.Vector3(p.x + size.x/2, p.y + size.y , p.z + size.z/2),
            new THREE.Vector3(p.x + size.x/2, p.y  , p.z + size.z/2),
            new THREE.Vector3(p.x - size.x/2, p.y + size.y , p.z + size.z/2),
            new THREE.Vector3(p.x - size.x/2, p.y  , p.z + size.z/2),
            new THREE.Vector3(p.x + size.x/2, p.y + size.y , p.z - size.z/2),
            new THREE.Vector3(p.x + size.x/2, p.y  , p.z - size.z/2),
            new THREE.Vector3(p.x - size.x/2, p.y + size.y , p.z - size.z/2),
            new THREE.Vector3(p.x - size.x/2, p.y  , p.z - size.z/2),
        ]
    },

    // 获取地址栏上的参数
    getUrlValue(name) {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let r = window.location.search.substr(1).match(reg);
        if (r != null) return decodeURIComponent(r[2]);
    return null;
    }
}