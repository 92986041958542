<template>
  <div id="app">
	  <div class="m-mask" v-if="loading" @click.stop.prevent @mousemove.stop.prevent>
	  	<img class="loading_icon" src="@/assets/loading_icon.png" alt="">
	  	<div class="loading_text">数字样车资源加载中...</div>
	  </div>
    <router-view/>
  </div>
</template>

<script>
	import pen from '@/model/showModel.js'
	import { mapState, mapMutations } from 'vuex'
	export default {
		destroyed(){
			pen.dispose()
		},
		computed: {
			...mapState(['type', 'loading']),  
		}
	}
</script>

<style lang="less">
	body,html,div,input,p{
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}
	.m-mask{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: #fff;
		text-align: center;
		font-size: 14px;
		background-color: rgba(0,0,0,.7);
		position: fixed;
		top:0;
		left:0;
		width: 100vw;
		height: 100vh;
		z-index: 9999;
		.loading_icon{
			width: 61px;
			height: 61px;
			display: block;
			margin-bottom: 12px;
		}
	}
</style>
