import Vue from 'vue'
import VueRouter from 'vue-router'
import orderDetail from '../views/orderDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'orderDetail',
    component: orderDetail
  },
	{
	  path: '/history',
	  name: 'history',
	  component: () => import ('../views/planList.vue')
	},
	{
	  path: '/digitalize',
	  name: 'digitalize',
	  component: () => import ('../views/digitalize.vue')
	},
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
