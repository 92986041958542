import * as THREE from"three"
import tool from '../baseTool.js'
import render from '../render'

let start,move,end;


class Event {
	constructor(el,camera,control) {
	    this.el = el;
		this.camera = camera;
		this.control = control;
		this.container = [];
		this.UIEvnetFn = null;

		this.hammer = new Hammer(this.el);
        this.hammer.get('rotate').set({enable: true});
        this.hammer.get('pinch').set({ enable: true });
		this.isRotate = false;
		this.rotateTimer = null;
		this.rotateStart = null;

		this.init()
	}

	init () {
		let isMove = false,
			isEnd = true,
			timer = null,
            dragFns = [],
			blurFns = [];

		start = (event) => {
			if (event.touches && event.touches.length > 1) {
				return
			}

            let dragFnsIsStop = false;
			isMove = false;
			isEnd = false;
            dragFns = this.container.filter(item => item.type === 'drag');
            blurFns = this.container.filter(item => item.type === 'blur');
            dragFns = dragFns.filter(item => {
            	if (!dragFnsIsStop) {
                    let intersets = this.intersetsObject(event, item);
                    if ((item.box&&intersets)|| (Array.isArray(intersets)&& intersets.length)) {
                        if(item.dragBefore ) item.dragBefore(intersets, tool.raycaster);
                        if (item.stopPropagation) dragFnsIsStop = true;
                        return  intersets
                    } else {
                        return false
                    }
				} else {
                    return false
				}
			});
			// dragTarget =

			timer = setTimeout(() =>{
				if (!isMove&&isEnd) {
					const clickFns = this.container.filter(item => item.type === 'click');
					if (Array.isArray(clickFns)&&clickFns.length) {
						for (let i = 0; i < clickFns.length; i++) {
							let item = clickFns[i];
                            const { callBack } = item;
                            let intersets = this.intersetsObject(event, item);
                            if ((item.box&&intersets)|| (Array.isArray(intersets)&& intersets.length)) {
                                callBack&&callBack(intersets, tool.raycaster);
								if (item.stopPropagation) {
                                	break;
								}
                            }
						}
					}
				}
				clearTimeout(timer)
			}, 300)
		};
		
		move = (event) => {
            if (event.touches && event.touches.length > 1) {
                return
            }
			isMove = true;
            dragFns.forEach(item => {
            	const { dragMove } = item;
                let center = tool.getMouseCenter(event,this.el);
                tool.setRayByMouse(center,render.camera,this.el);
                dragMove&&dragMove(tool.raycaster,event)
			})
		};

		end = () => {
            if (event.touches && event.touches.length > 1) {
                return
            }
			isEnd = true;
            dragFns.forEach((item) => {
				const { callBack } = item;
                callBack&&callBack();
			});
            dragFns = [];
		};

        this.el.addEventListener('mousedown',start);
        this.el.addEventListener('mousemove',move);
        this.el.addEventListener('mouseup',end);

        this.el.addEventListener('touchstart',start);
        this.el.addEventListener('touchmove',move);
        this.el.addEventListener('touchend',end);


        this.hammer.on('rotate', (event) => {
            this.isRotate = true;
            if (this.rotateTimer) clearTimeout(this.rotateTimer);
            let x = event.pointers[0].clientX - event.pointers[1].clientX;
            let y = event.pointers[0].clientY - event.pointers[1].clientY;
            let radian = Math.atan2(x, y);
            if (this.rotateStart) {
				this.container.forEach((item) => {
					if (item.type === 'rotate') {
                        item.rotate && item.rotate(radian - this.rotateStart);
					}
				});
            }
            this.rotateStart = radian
        });
        this.hammer.on('rotateend', () => {
            this.rotateTimer = setTimeout(() => {
                this.isRotate = false;
                // if(this.sceneType)this.control.start(this.control.states.device);
                this.container.forEach((item) => {
                	if (item.type === 'rotate') {
                        item.callBack && item.callBack();
					}
				});
                clearTimeout(this.rotateTimer);
                this.rotateTimer = null;
            }, 500);
            this.rotateStart = 0;
        });

        this.hammer.on('pinchstart',() => {
            if(this.sceneType){
                this.control.stop();
                pinchFlag = true;
//                    if(this.selectedFurniture){
//                        scaleVector.copy(this.selectedFurniture.mesh.scale);
                this.selectedFurniture.initScale = 1;
//                    }
            }

        });
        this.hammer.on('pinchmove',() => {
        });
        this.hammer.on('pinchend',() => {
            if(this.sceneType){
                pinchFlag = false;
                this.control.start(this.control.states.device)
            }
        });
        this.hammer.on('pinchcancel',() => {
            if(this.sceneType){
                this.control.start(this.control.states.device)
            }

        });
        this.hammer.on('pinchin',(e) => {
            if(!this.sceneType)return;
            if(this.selectedFurniture&&this.selectedFurniture.type === 4){
                if( this.selectedFurniture.mesh.scale.x < 0.5){
                    return
                }
                this.selectedFurniture.mesh.scale.multiplyScalar(1 - ((this.selectedFurniture.initScale || 0) - e.scale));
                this.selectedFurniture.initScale = e.scale;

            }
        });
        this.hammer.on('pinchout',(e) => {
            if(!this.sceneType)return;
            if(this.selectedFurniture&&this.selectedFurniture.type === 4){
                if(this.selectedFurniture.mesh.scale.x >= 2){
                    return
                }
                this.selectedFurniture.mesh.scale.multiplyScalar(1 - ((this.selectedFurniture.initScale || 0) - e.scale ));
                this.selectedFurniture.initScale = e.scale;
            }
        });
	}

	/**
	 * 	添加事件
	 * 	@params options.type {String} 事假类型
	 *  @params options.object3D {Object} 要操作的对象
	 *  @params options.box { THREE.Box3 } 减少判断性能消耗的 box
     *  @params options.boxUpdate { Function } 更新box的方法
	 *
	 *  @params options.dragBefore { Function } 拖拽之前
	 *  @params options.dragMove { Function } 拖拽中
	 *
	 *  @params options.callBack {Functjion} 操作函数
	 *
	 *  @params opitons.order { Number } 事件执行顺序 数字越大 越早执行
		@params options.stopPropagation { Boolean } 停止传播
	 * */
	
	addEvent( options ){
		this.container.push(options);
		options.order = options.order || 0;
		this.container.sort((a,b) => {
			return b.order - a.order
		})
	}

	intersetsObject(event, eventOptions) {

		const { object3D, box, boxUpdate } = eventOptions;
		const obj = box || object3D;
		if (box) {
            boxUpdate&&boxUpdate();
            let center = tool.getMouseCenter(event,this.el);
            tool.setRayByMouse(center,render.camera,this.el);
            const ray = tool.raycaster.ray;
			return ray.intersectsBox(box)
		} else {
            return tool.getIntersetsByEvent(event,render.camera,object3D,this.el)
		}


	}
	
	/**
	 * 删除对模型的时间
	 * 	@params type {String} 事假类型
	 *  @params object3D {Object} 要操作的对象
	 *  @params callBack {Functjion} 删除的函数 为空时删除所有
	 * **/

	removeEvent(type,object3D,callBack) {
        this.container.forEach(item => {
        	let typeFlag = true;
        	if (type) {
                typeFlag = type === item.type
			}
			let cbFlag = true;
			if (callBack) {
				cbFlag = callBack === item.callBack
			}
        	if ( typeFlag && item.object3D === object3D && cbFlag) {
				this.container.splice(this.container.indexOf(item),1)
			}
        });
	}

	setUIEventFn (fn) {
		this.UIEvnetFn = fn
	}

	dispatchEvent (EventName,data) {
		this.UIEvnetFn(EventName,data)
	}

	dispose() {
		this.container.forEach(item => {
			this.removeEvent(item.type,item.object3D,item.callback)
		})
        this.el.removeEventListener('mousedown',start);
        this.el.removeEventListener('mousemove',move);
        this.el.removeEventListener('mouseup',end);

        this.el.removeEventListener('touchstart',start);
        this.el.removeEventListener('touchmove',move);
        this.el.removeEventListener('touchend',end);
	}
}

export default Event