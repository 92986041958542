import * as THREE from 'three'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"
// import { createBackground } from './panoBackground/three-vignette'
const Box = new THREE.Box3();


const IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const loader = new GLTFLoader();
const dLoader = new DRACOLoader();
dLoader.setDecoderPath('./libs/draco/');
loader.setCrossOrigin('anonymous');
loader.setDRACOLoader(dLoader);

// 渲染器
class render {

    constructor () {
		this.renderer = null
        this.renderState = true; // 渲染状态
        this.updateList = [];
		this.cameras = []
		this.scenes = []
		this.scene = null
		this.camera = null
        this.raycaster = new THREE.Raycaster();
        this.reSizeFn = null; // 界面发生大小变化时的方法
		this.lights = []
		this.init() 
    }

    // 初始渲染器
    init (el) {
        // this.el = el;
        this._createRenderer();
        // this._reSizeInit();
        // this._render();
        this._initScene()
        // this.addUpdateFn(() => {TWEEN.update()})
    }
	
    // 创建webGL上下文
    _createRenderer () {
        let renderer = new THREE.WebGLRenderer({
            antialias: true,
            logarithmicDepthBuffer: true,
            preserveDrawingBuffer: true,   // required to support .toDataURL()
            alpha:true
        });
        window.rendererInfo = renderer.info;
        // renderer.setSize(this.el.offsetWidth, this.el.offsetHeight);
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.gammaOutput = true;
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.setClearColor(0xaaaaaa, 0.0);
        renderer.sortObjects = true;
		renderer.toneMappingExposure = -1.34;
        // renderer.autoClear = false;
        this.renderer = renderer;
        // this.el.appendChild(this.renderer.domElement);
    }

    // 渲染
    render () {
        const animation = () => {
            if (!this.renderState) return;
            this.renderer.clear();
            this.updateList.forEach((fn) => {
                fn();
            });
            requestAnimationFrame(animation)
        };
		let timer = setTimeout(() => {
			this.renderState = true
			animation()
			clearTimeout(timer)
			timer = null
		}, 50)
        
    }

    // 更新大小
    reSizeInit (el) {
		this.el = el
        this.renderer.setSize(this.el.offsetWidth, this.el.offsetHeight);
        this.reSizeFn = () => {
            this.renderer.setSize(this.el.offsetWidth, this.el.offsetHeight);
            this.upDateCamerasAspect();
        };
        window.addEventListener('resize', this.reSizeFn);
		this.upDateCamerasAspect()
    }

    _initScene () {
        // 主场景
        this.scene = this.createScene();
        this.camera = this.createCamera();
        this.scene.add(this.camera);
        // const bg = createBackground({
        //     aspect: this.camera.aspect,
        //     grainScale: IS_IOS ? 0 : 0.001, // mattdesl/three-vignette-background#1
        // });
        // this.scene.add(bg);
        this.addUpdateFn(() => {
            this.renderer.render(this.scene, this.camera)
        });
        // // 渐显场景
        // this.fateInScene = this.createScene();
        // this.fateInCamera = this.createCamera();
        // this.fateInScene.add(this.fateInCamera);
        // // 渐隐场景
        // this.zoomOutScene = this.createScene();
        // this.zoomOutCamera = this.createCamera();
        // this.zoomOutScene.add(this.zoomOutCamera);
    }

    // 添加更新项
    addUpdateFn (fn) {
        this.updateList.push(fn)
    }

    // 删除更新项
    removeUpdateFn (fn) {
        if (this.updateList.indexOf(fn) !== -1) this.updateList.splice(this.updateList.indexOf(fn), 1)
    }

   // 创建相机
    createCamera () {
        const camera = new THREE.PerspectiveCamera(45, 1, 1, 200000);
        camera.up.set(0,1,0);
        // camera.lookAt(new THREE.Vector3());
        this.cameras.push(camera);
        return camera;
    }
	
	// 添加灯光
	addLight(light) {
		this.lights.push(light)
		this.camera.add(light)
	}
	// 删除灯光
	clearLight(){
		this.lights.forEach(item => {
			this.camera.remove(item)
		})
		
	}
	

    // 创建场景
    createScene () {
        const scene = new THREE.Scene();
        this.scenes.push(scene);
        // scene.add(new THREE.AxesHelper(10000))
        return scene
    }

    // 更新相机的比例
    upDateCamerasAspect () {
        this.cameras.forEach(camera => {
            camera.aspect = this.el.offsetWidth / this.el.offsetHeight;
            camera.updateProjectionMatrix();
        })
    }

    // 加载模型 glb
    loadModel(url, onProgress) {
        return new Promise((resolve, reject) => {
            loader.load(url, (res) => {
                resolve(res)
            }, function (res) {
                onProgress && onProgress(res)
            }, function (err) {
                reject(err)
            });
        })
    }

    // 设置相机视角
    setCameraFov (deg) {
        this.camera.fov = deg;
        this.fateInCamera.fov = deg;
        this.zoomOutCamera.fov = deg;
        this.camera.updateProjectionMatrix();
    }
	
	//
	lookAtModel(mesh, options = {}) {
		let { xZoom, zZoom, yZoom } = options
		const Size = new THREE.Vector3()
		Box.setFromObject(mesh);
		Box.getSize(Size)
		let dis = Size.distanceTo(new THREE.Vector3());
		console.log(options)
		this.camera.position.set(dis * (xZoom || 1), dis * (zZoom || .6), dis * (yZoom || 1));
		
		this.camera.lookAt(new THREE.Vector3());
	}

    // 注销
    dispose () {
		console.log('dispose')
        this.renderState = false;
        this.renderer.forceContextLoss();
        this.renderer.context = null;
        this.renderer.domElement = null;
        this.renderer = null;

        window.removeEventListener('resize',this.reSizeFn);

       this.cameras = [];
       this.scenes.forEach(scene => {
           scene.traverse(node => {
               if (node.isMesh) {
                   let material = node.material;
                   if (material) {
                       let map = material.map;
                       if(map && Array.isArray(map)) {
                           map.forEach(item => {
                               item.dispose()
                           });
                           map = null;
                       } else {
                           map.dispose();
                           map = null;
                       }
                       material.dispose();
                       material = null;
                   }
               }
           })
       })
    }}

export default  new render();