import * as THREE from 'three'
import render from './render.js'
import control from './control/control'

class Pen {
	constructor() {
		this.el = null
		this.init()
		this.renderModel = null
		this.render = render
		this.control = control
		window.__pen = this
	}
	
	init () {
	    render.init();
	    // control.init(el);
	    // control.event.setUIEventFn((evnetName, data) => {
	    //     this.dispatchEvent(evnetName, data)
	    // });
	    // this.sceneEventInit();
	}
	
	setCanvasBox(el) {
		this.el = el
		render.reSizeInit(el)
		control.init(el);
		this.el.appendChild(render.renderer.domElement);
		render.render()
	}
	
	async loadModel(src, renderOrder) {
		let model = await render.loadModel(src)
		let mesh = model.scene
		mesh.traverse(node => {
		    if ( node.isMesh && node.material ) {
		        if (Array.isArray(node.material)) {
		            node.material.forEach(material => {
		                material.transparent = true;
		                material.needsUpdate = true;
		            })
		        } else {
		            node.material.transparent = true;
		            node.material.needsUpdate = true;
		        }
		    }
		});
		mesh.renderOrder = 0 || renderOrder;
		
		return model
	}
	
	showModel (model, options) {
		if (this.renderModel) {
			 render.scene.remove(this.renderModel.scene)	
		}
		this.renderModel = model
		render.scene.add(model.scene)
		render.lookAtModel(model.scene, options)
	}
	
	changeLightType (type) {
		if (type) {
			render.clearLight()
			render.addLight(new THREE.AmbientLight(0xffffff, .3))
			render.addLight(new THREE.DirectionalLight(0xffffff, .5))
		} else {
			render.clearLight()
			render.addLight(new THREE.AmbientLight(0xffffff, 1))
			render.addLight(new THREE.DirectionalLight(0xffffff, 2.5))
		}
	}
	
	stopShow(){
		control.dispose()
		render.renderState = false
		render.renderer.clear()
		
		if (this.renderModel) {
			 render.scene.remove(this.renderModel.scene)	
		}
	}
	
	dispose() {
		
		render.dispose()
	}
	
}

export default new Pen()