<template>
  <div class="orderDetail-page">
	  <div class="car_name_id">
		  <div class="car_name">C673</div>
		  <div class="car_id">纯电车版（600）</div>
	  </div>
	  <div class="order_info">您的订单预计4周后进入排产</div>
	  <div class="car_img_box">
		<img class="car_image" src="@/assets/orderDetail/car_test.jpg" alt="">
	  </div>
	  <div class="car_info_item" v-for="item in infos" :key="item.label">
		  <div class="car_info_left">
			  <div class="info_label">
				  <div class="info_label_text">{{ item.label }}</div>
				  <div class="info_status_text" :class="{ active: item.status === 1, unDone: item.status === 2 }">{{ item.statusText }}</div>
				
			  </div>
			  <div class="info_text">{{ item.content }}</div>
		  </div>
		  <div class="car_info_right">
			  <img class="car_info_img" :src="item.img" alt="">
		  </div>
	  </div>
	  <div class="footer">
		  <div class="footer_btn" @click="onLink('digitalize')">数字样车</div>
		  <div class="footer_btn" @click="onLink('history')">生产计划</div>
	  </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'orderDetail',
  components: {
  },
  data: () => ({
	  infos: [
		  { label: '外观', content: '月岩灰', img: require('@/assets/orderDetail/info_1.png'), status: 0, statusText: '已完成'},
		  { label: '增程', content: '增程1200', img: require('@/assets/orderDetail/info_2.png'), status: 1, statusText: '进行中'},
		  { label: '内饰颜色', content: '晨曦粉', img: require('@/assets/orderDetail/info_3.png'), status: 2, statusText: '预计2023.2.3开始'},
		  { label: '座椅', content: '前排零重力变换座椅+12.3副驾屏', img: require('@/assets/orderDetail/info_4.png'), status: 2, statusText: '预计2023.2.3开始'},
		  { label: '轮毂', content: '20英寸运动轮毂+邓禄普', img: require('@/assets/orderDetail/info_5.png'), status: 2, statusText: '预计2023.2.3开始'},
		  { label: '车灯', content: '车外只能交互灯+前后流水灯+D柱充电指示灯(双侧)', img: require('@/assets/orderDetail/info_6.png'), status: 2, statusText: '预计2023.2.3开始'},
		  { label: '辅助服务', content: 'NID3.0高阶辅助驾驶服务', img: require('@/assets/orderDetail/info_7.png'), status: 2, statusText: '预计2023.2.3开始'},
		  { label: '钥匙', content: '智能实体钥匙（已全系预埋硬件）', img: require('@/assets/orderDetail/info_8.png'), status: 2, statusText: '预计2023.2.3开始'},
	  ]
  }),
  computed: {
	...mapState(['type', 'loading']),  
  },
  methods: {
	  ...mapMutations(['setType', 'setLoading']),
	  onLink(name) {
		  this.setLoading( true )
		  setTimeout(() => {
			  this.setLoading( false )
			  this.$router.push({name})
		  }, 500)
		  
	  }
  }
}
</script>

<style scoped lang="less">
	.orderDetail-page{
		background: rgb(244,245,249) url(@/assets/orderDetail/bg.png) no-repeat center top;
		background-size: cover;
		position: relative;
		padding-bottom: 100px;
		.car_name_id {
			position: absolute;
			top: 20px;
			left: 20px;
			color: #333;
			.car_name{
				font-size: 20px;
			}
			.car_id{
				font-size: 14px;
			}
		}
		.order_info{
			background: #dedee9 url(@/assets/orderDetail/time_icon.png) no-repeat 6px center;
			background-size: 20px 20px;
			padding: 0 15px;
			padding-left: 30px;
			font-size: 14px;
			position: absolute;
			top: 20px;
			right:0;
			line-height: 24px;
			border-radius: 6px 0 0 6px;
		}
		.car_img_box{
			height: 350px;
			padding-top: 50px;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			.car_img{
				max-width: 100%;
				max-height: 100%;
			}
		}
		.car_info_item{
			width: 89%;
			padding: 12px 15px;
			background-color: #fff;
			border-radius: 12px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 0 auto;
			margin-bottom: 12px;
			.car_info_left{
				font-size: 14px;
				line-height: 22px;
				padding-right: 20px;
				.info_label{
					color: #aaa;
					display: flex;
					.info_label_text{
						margin-right: 12px;
					}
					.info_status_text{
						height: 20px;
						line-height: 20px;
						padding: 0 6px;
						border-radius: 4px;
						background-color:#F1F1F6;
						color: #a1a9b9;
						font-size: 12px;
						font-weight: 500;
						&.active{
							color: #BA9B5F;
							background-color: #FBF7EF;
						}
						&.unDone{
							color: #7F7CA5;
							background-color: #E2E2EC;
						}
					}
					
				}
				.info_text{
					color: #333;
					font-weight: bold;
				}
			}
			.car_info_right{
				height: 54px;
				width: 75px;
				border-radius: 8px;
				overflow: hidden;
				flex: 0 0 auto;
				.car_info_img{
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}
		.footer{
			position: fixed;
			bottom: 0;
			background-color: #fff;
			padding: 24px 0;
			width: 100%;
			display: flex;
			justify-content: center;
			.footer_btn{
				background-image: linear-gradient(to right, rgb(24,29,73) , rgb(72,78,105));
				font-size: 16px;
				text-align: center;
				color: #fff;
				line-height: 50px;
				width: 90%;
				margin:0 7.5px;
				border-radius: 8px;;
				width: 140px;
			}
		}
	}
</style>
