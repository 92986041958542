import * as THREE from 'three'
window.THREE = THREE;
import DeviceControls from './M_deviceOrientationControls'

let
    isUserInteracting = false,//用户是否正在操作
    lon = 0,  onPointerDownLon= 0.0,onPointerDownPointerX = 0,//经度
    lat = 0,  onPointerDownLat= 0.0,onPointerDownPointerY = 0,//纬度
    phi = 0, theta = 0,//计算相机位置的重要参数
    is_touch=false,targetR = 500,renderlon = 0,renderlat = 0,alphaOffset = Math.PI/2; // radians;//target球半径;

let deviceOrientation,screenOrientation,deviceQ = new THREE.Quaternion(),needDeviceUpdate = false,cameraPhi = 0,cameraTheta = 0,deviceCameeraPhi = 0;

let raycaster = new THREE.Raycaster();//拾取场景里面的物体,可判断点击或交互事件对应的元素
let mouse = new THREE.Vector2();//二维向量的对象，鼠标计算

class PanoControl {
    constructor(camera,el) {
        this.camera = camera;
        this.target = new THREE.Vector3();
        this.el = el;
        this.enabled = true;
        this.touchEnabled = true;
        this.deviceEnabled = false;
        this.maxPolarAngle = THREE.Math.radToDeg( .75 * Math.PI);
        this.minPolarAngle =  THREE.Math.radToDeg( .25 * Math.PI);
        this.devicePermission = false;
        this.init();

    }

    init() {
        const u = navigator.userAgent, app = navigator.appVersion;
        const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //g
        const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isAndroid) {
            this.devicePermission = true;
        }
        this.eventInit()
    }

    eventInit() {
        //鼠标、手机touch的各个事件
        this.el.addEventListener( 'mousedown', this._onDocumentMouseDown.bind(this), false );
        this.el.addEventListener( 'mousemove', this._onDocumentMouseMove.bind(this), false );
        this.el.addEventListener( 'mouseup', this._onDocumentMouseUp.bind(this), false );

        this.el.addEventListener( 'touchstart', this._onDocumentTouchDown.bind(this), false );
        this.el.addEventListener( 'touchmove', this._onDocumentTouchMove.bind(this), false );
        this.el.addEventListener( 'touchend', this._onDocumentTouchUp.bind(this), false );
        // document.addEventListener( 'wheel', onDocumentMouseWheel, false );

        if (this.devicePermission) {
            window.addEventListener( 'orientationchange', this._onScreenOrientationChangeEvent.bind(this), false );
            window.addEventListener( 'deviceorientation', this._onDeviceOrientationChangeEvent.bind(this), false );
        }

    }

    deviceEventInit () {
        DeviceMotionEvent.requestPermission().then((permissionState) => {
            if (permissionState === 'granted') {
                this.devicePermission = true;
                window.addEventListener( 'orientationchange', this._onScreenOrientationChangeEvent.bind(this), false );
                window.addEventListener( 'deviceorientation', this._onDeviceOrientationChangeEvent.bind(this), false );
            } else {

            }
        })
    }

    targetInit() {
        let normal = new THREE.Vector3(1,0,0);
        this.camera.getWorldDirection(normal);
        normal.multiplyScalar(500);
        let _phi= Math.acos(normal.y/targetR);
        let _c2 = targetR * Math.sin(_phi);
        // let _theta = Math.asin(normal.z/_c2);
        let _theta = Math.acos(normal.x/_c2);
        if(normal.z<0)_theta = 2 * Math.PI - _theta;
        lat = renderlat = THREE.Math.radToDeg(_phi);
        lon = renderlon = THREE.Math.radToDeg(_theta);
        needDeviceUpdate = false;
        cameraPhi = Math.PI - _phi;
        cameraTheta = Math.PI - _theta;
    }

    dispose() {
        this.el.removeEventListener( 'mousedown', this._onDocumentMouseDown, false );
        this.el.removeEventListener( 'mousemove', this._onDocumentMouseMove, false );
        this.el.removeEventListener( 'mouseup', this._onDocumentMouseUp, false );

        this.el.removeEventListener( 'touchstart', this._onDocumentTouchDown, false );
        this.el.removeEventListener( 'touchmove', this._onDocumentTouchMove, false );
        this.el.removeEventListener( 'touchend', this._onDocumentTouchUp, false );
        // document.addEventListener( 'wheel', onDocumentMouseWheel, false );
        if (this.devicePermission) {
            window.removeEventListener( 'orientationchange', this._onScreenOrientationChangeEvent, false );
            window.removeEventListener( 'deviceorientation', this._onDeviceOrientationChangeEvent, false );
        }
    }

    update() {
        if(this.enabled){
            if(this.touchEnabled){
                this._touchUpdate();
            }
            if(this.deviceEnabled&&!is_touch&&!isUserInteracting){
               this._deviceUpdate();
            }
        }
    }

    _touchUpdate(){
        phi = -THREE.Math.degToRad( renderlat - lat );
        theta = -(THREE.Math.degToRad( renderlon - lon));
        renderlat = lat;
        renderlon = lon;

        if(cameraPhi + phi > Math.PI * 0.75){
            phi = Math.PI * 0.75 - cameraPhi
        }else if(cameraPhi + phi < Math.PI* 0.25){
            phi =  Math.PI* 0.25 - cameraPhi
        }
        cameraPhi += phi;
        cameraTheta +=theta;
        this.camera.rotateOnWorldAxis(new THREE.Vector3(0,1,0),theta);
        this.camera.rotateX(phi);
    }

    _deviceUpdate() {
        let device = deviceOrientation;
        if ( device ) {
            let alpha = device.alpha ? THREE.Math.degToRad(device.alpha) + alphaOffset : 0; // Z

            let beta = device.beta ? THREE.Math.degToRad(device.beta) : 0; // X

            let gamma = device.gamma ? THREE.Math.degToRad(device.gamma) : 0; // Y

            let orient = screenOrientation ? THREE.Math.degToRad(screenOrientation) : 0; // O

            PanoControl.setObjectQuaternion(deviceQ, alpha, beta, gamma, orient );

            let p = new THREE.Vector3(0,0,-300);
            p.applyQuaternion(deviceQ);
            let _phi= Math.acos(p.y.toFixed(6)/300);
            let _c2 = 300 * Math.sin(_phi);
            // let _theta = Math.asin(normal.z/_c2);
            let _theta = Math.acos(p.x/_c2);
            if(p.z<0)_theta = 2 * Math.PI - _theta;

            lat = THREE.Math.radToDeg(_phi);
            lon = THREE.Math.radToDeg(_theta);



            if(needDeviceUpdate){
                phi = THREE.Math.degToRad( lat - renderlat);
                theta = THREE.Math.degToRad( lon  - renderlon);
                renderlat = lat;
                renderlon = lon;

                if(cameraPhi - phi > Math.PI * 0.75){
                    phi = Math.PI * 0.75 - cameraPhi
                }else if(cameraPhi - phi < Math.PI* 0.25){
                    phi =  Math.PI* 0.25 - cameraPhi
                }

                this.camera.rotateOnWorldAxis(new THREE.Vector3(0,1,0),-theta);
                this.camera.rotateX(-phi);


                cameraPhi += -phi;
            }else{
                renderlat = lat;
                renderlon = lon;
                phi = THREE.Math.degToRad( lat);
                theta = THREE.Math.degToRad( lon);
                needDeviceUpdate = true;
            }
        }
    }

    _onDocumentMouseDown( event ) {
        event.preventDefault();
        isUserInteracting = true;
        onPointerDownPointerX = event.clientX;
        onPointerDownPointerY = event.clientY;
        onPointerDownLon = lon;
        onPointerDownLat = lat;
    }
    _onDocumentMouseMove( event ) {
        if ( isUserInteracting === true ) {
            lon = ( onPointerDownPointerX - event.clientX ) * 0.1 + onPointerDownLon;
            lat = ( event.clientY - onPointerDownPointerY ) * 0.1 + onPointerDownLat;
        }
    }
    _onDocumentMouseUp( event ) {
        isUserInteracting = false;
        needDeviceUpdate = false;
    }

    //  touch event start
    _onDocumentTouchDown( event ) {
        is_touch=true;
        event.preventDefault();
        isUserInteracting = true;
        onPointerDownPointerX = event.touches[ 0 ].clientX;
        onPointerDownPointerY = event.touches[ 0 ].clientY;
        if(this.enabled){
            this.targetInit();
            onPointerDownLon = lon;
            onPointerDownLat = lat;
        }
    }
    _onDocumentTouchMove( event ) {
        if(this.enabled){
            if ( isUserInteracting === true ) {
                lon = ( event.touches[ 0 ].clientX - onPointerDownPointerX ) * 0.2 + onPointerDownLon;
                lat = ( event.touches[ 0 ].clientY - onPointerDownPointerY ) * 0.2 + onPointerDownLat;
                // lat = lat > this.maxPolarAngle? this.maxPolarAngle : lat < this.minPolarAngle ? this.minPolarAngle : lat;
            }
        }
    }
    _onDocumentTouchUp( event ) {
        is_touch=false;
        isUserInteracting = false;
        needDeviceUpdate = false;
    }
    //  touch event end

    _onDeviceOrientationChangeEvent( event ) {

        deviceOrientation = event;

    };

    _onScreenOrientationChangeEvent () {

        screenOrientation = window.orientation || 0;

    };

    static setObjectQuaternion = function () {

        let zee = new THREE.Vector3( 0, 0, 1 );

        let euler = new THREE.Euler();

        let euler2 = new THREE.Euler();

        let q0 = new THREE.Quaternion();

        let q1 = new THREE.Quaternion( - Math.sqrt( 0.5 ), 0, 0, Math.sqrt( 0.5 ) ); // - PI/2 around the x-axis

        return function ( quaternion, alpha, beta, gamma, orient ) {
            euler.set( beta, alpha, - gamma, 'YXZ' ); // 'ZXY' for the device, but 'YXZ' for us

            quaternion.setFromEuler( euler ); // orient the device

            quaternion.multiply( q1 ); // camera looks out the back of the device, not the top

            quaternion.multiply( q0.setFromAxisAngle( zee, - orient ) ); // adjust for screen orientation
        };

    }();
}

export default  PanoControl